import React from 'react';
import styled, { css, keyframes } from 'styled-components';

interface IButton extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  primary?: boolean;
  variant?: 'normal' | 'large';
  loading?: boolean;
}

const StyledButton = styled.button<IButton>`
  font-weight: 600;
  &:focus {
    background-color: ${({ theme }) => theme.colors.primary};
  }
  ${(props) =>
    props.primary &&
    css`
      background-color: ${({ theme }) => theme.colors.primary};
      color: white;
    `}
  ${(props) =>
    props.disabled &&
    css`
      opacity: 0.4;
    `}
  ${(props) =>
    props.variant === 'large' &&
    css`
      height: 50px;
    `}
  ${(props) =>
    props.loading &&
    css`
      pointer-events: none;
    `}
`;

const spin = keyframes`
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
`;

const Spinner = styled.div`
  border: 4px solid rgba(0, 0, 0, 0.5);
  border-top: 4px solid lightgrey;
  border-radius: 50%;
  width: 16px;
  height: 16px;
  animation: ${spin} 2s linear infinite;
  display: block;
  margin: auto;
`;

const Button: React.FC<IButton> = ({ children, ...props }) => (
  <StyledButton {...props}>
    {props.loading ? <Spinner /> : children}
  </StyledButton>
);

export default Button;
