import ai from '../images/rooms/ai.gif';
import baseball from '../images/rooms/baseball.gif';
import brewmeister from '../images/rooms/brewmeister.gif';
import cafeteriua from '../images/rooms/cafeteriua.gif';
import cellar from '../images/rooms/cellar.gif';
import chaff from '../images/rooms/chaff.gif';
import corridor from '../images/rooms/corridor.gif';
import corridorqamcom from '../images/rooms/corridorqamcom.gif';
import elevator from '../images/rooms/elevator.gif';
import elevators1 from '../images/rooms/elevators1.gif';
import elevators2 from '../images/rooms/elevators2.gif';
import emccave from '../images/rooms/emccave.gif';
import founders from '../images/rooms/founders.gif';
import garage from '../images/rooms/garage.gif';
import gym from '../images/rooms/gym.gif';
import hugin from '../images/rooms/hugin.gif';
import insideelevator from '../images/rooms/insideelevator.gif';
import kitchen from '../images/rooms/kitchen.gif';
import librixer from '../images/rooms/librixer.gif';
import lobby1 from '../images/rooms/lobby1.gif';
import lobby2 from '../images/rooms/lobby2.gif';
import lockers from '../images/rooms/lockers.gif';
import malmsteen from '../images/rooms/malmsteen.gif';
import pandora from '../images/rooms/pandora.gif';
import quotewall from '../images/rooms/quotewall.gif';
import roof from '../images/rooms/roof.gif';
import school from '../images/rooms/school.gif';
import stairs from '../images/rooms/stairs.gif';
import teal from '../images/rooms/teal.gif';
import threedoorways from '../images/rooms/threedoorways.gif';
import threedoorways2 from '../images/rooms/threedoorways2.gif';
import threedoorways3 from '../images/rooms/threedoorways3.gif';
import wolfgang from '../images/rooms/wolfgang.gif';
import qamcombuilding from '../images/qamcombuilding.gif';
import goodending from '../images/rooms/goodending.gif';

export default {
  ai,
  baseball,
  brewmeister,
  cafeteriua,
  cellar,
  chaff,
  corridor,
  corridorqamcom,
  elevator,
  elevators1,
  elevators2,
  emccave,
  founders,
  garage,
  gym,
  hugin,
  insideelevator,
  kitchen,
  librixer,
  lobby1,
  lobby2,
  lockers,
  malmsteen,
  pandora,
  quotewall,
  roof,
  school,
  stairs,
  teal,
  threedoorways,
  threedoorways2,
  threedoorways3,
  wolfgang,
  qamcombuilding,
  goodending
};
