import { navigate } from 'gatsby-link';
import React, { useContext, useEffect } from 'react';
import styled, { css } from 'styled-components';
import Button from '../components/shared/Button';
import Spacer from '../components/shared/Spacer';
import { AudioContext } from '../contexts/AudioContext';
import { EAudioFileType } from '../models';
import Breakpoints from '../services/breakpoints';
import { getProfession, getName, getHasPlayed } from '../services/localStorage';
import settings from '../styles/settings';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  align-items: center;
  max-width: ${Breakpoints.DESKTOP};
  margin: auto;
  @media screen and (min-width: ${Breakpoints.MOBILE}) {
    max-width: ${Breakpoints.MOBILE};
  }
`;

const HeroContainer = styled.div`
  flex: 1;
  position: relative;
`;

const HeroBanner = styled.img`
  height: 50vh;
  object-fit: contain;
  @media screen and (max-width: ${Breakpoints.MOBILE}) {
    height: auto;
    width: 100%;
  }
`;

const HeroOverlay = styled.div`
  position: absolute;
  height: 20%;
  left: 0;
  bottom: 0;
  right: 0;
  background-image: linear-gradient(
    to top,
    rgba(0, 0, 0, 1) 0%,
    rgba(0, 0, 0, 0) 100%
  );
`;

const ContainerBottom = styled.div`
  flex: 1;
  width: 80%;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const ContainerButton = styled.div<{ isLoggedIn: boolean }>`
  width: 90%;
  display: flex;
  flex: 1;
  justify-content: flex-end;
  flex-direction: column;
  align-items: center;
  ${(props) =>
    props.isLoggedIn &&
    css`
      flex-direction: column-reverse;
    `}
`;

const StartButton = styled(Button)`
  font-size: 1.5rem;
`;

const Title = styled.h1`
  font-size: 2.2rem;
  margin: 0;
  text-align: center;
  padding: 0;
`;

const NickTitle = styled.p`
  font-size: 1.5rem;
  letter-spacing: 8px;
`;

const IndexPage = () => {
  const { play } = useContext(AudioContext);
  const nickname = getName();

  const handleFirstOptionButtonClick = () => {
    play(EAudioFileType.INTRO);
    navigate(!!nickname ? '/highscore' : '/signin');
  };

  const handleSecondOptionButtonClick = () => {
    play(EAudioFileType.INTRO);
    navigate(!!nickname ? '/backpack' : '/register');
  };

  const handleMainButtonClick = () => {
    play(EAudioFileType.INTRO);
    if (getHasPlayed() === 'true' && !nickname) {
      navigate('/end');
    } else if (getProfession()) {
      navigate('/game');
    } else {
      navigate('profession');
    }
  };

  return (
    <Container>
      <HeroContainer>
        <HeroBanner src={settings.qamcombuilding} alt="hero banner" />
        <HeroOverlay />
      </HeroContainer>
      <ContainerBottom>
        {!!nickname ? (
          <>
            <Title>Welcome back!</Title>
            <NickTitle>{nickname}</NickTitle>
          </>
        ) : (
          <>
            <Spacer />
            <Title>Welcome</Title>
            <Title>adventurer!</Title>
          </>
        )}
        <Spacer spacing={3} />
        <ContainerButton isLoggedIn={!!nickname}>
          <StartButton primary variant="large" onClick={handleMainButtonClick}>
            {!!nickname ? 'KEEP EXPLORING' : 'START GAME'}
          </StartButton>
          <Spacer spacing={1.5} />
          <Button onClick={handleFirstOptionButtonClick}>
            {!!nickname ? <>View highscore &#8250;</> : 'Sign In'}
          </Button>
          <Spacer spacing={1.5} />
          <Button onClick={handleSecondOptionButtonClick}>
            {!!nickname ? <>Open backpack &#8250;</> : 'Register'}
          </Button>
        </ContainerButton>
      </ContainerBottom>
    </Container>
  );
};

export default IndexPage;
